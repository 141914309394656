import React from 'react';

import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/core';
import FormikInput from 'components/primitives/FormikInput';
import { Form, Formik } from 'formik';

import KRLogo from '../../res/kilter_one-color_dark_tag.png';
import { LoginGeneratedProps } from './Login.props';
import { Container } from './Login.style';
import { LoginSchema } from './Login.utils';

const LoginView = (props: LoginGeneratedProps): JSX.Element => (
  <Container>
    <Box marginX="auto" w="100%" maxW={600}>
      <Image src={KRLogo} w="100%" />
    </Box>
    <Box w="100%" marginX="auto" maxW={400} mt={5}>
      <Stack bg="white" spacing={8} shadow="md" p={10} borderRadius={5}>
        <Heading color="#2e2c44" textAlign="center">
          Admin Login
        </Heading>
        <Formik
          initialValues={{}}
          onSubmit={props.submitLogin}
          validationSchema={LoginSchema}
        >
          <Form>
            <FormikInput name="email" label="Email" />
            <FormikInput name="password" type="password" label="Password" />
            <Button
              type="submit"
              width="100%"
              mt={10}
              bg="#2e2c44"
              color="white"
              isLoading={props.isLoading}
            >
              Submit
            </Button>
            {props.error && (
              <Text color="red.500" fontSize="sm">
                {props.error}
              </Text>
            )}
          </Form>
        </Formik>
      </Stack>
    </Box>
  </Container>
);

export default LoginView;
