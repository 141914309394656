import api from 'api/instance';
import { AxiosPromise } from 'axios';
import { ApiResponse } from 'types/api';
import { Session } from 'types/session';

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/authentication`;

interface LoginPayload {
  email: string;
  password: string;
}

export const login = async (
  payload: LoginPayload
): Promise<ApiResponse<Session>> => {
  const { data } = await api.post<ApiResponse<Session>>(
    `${URL}/login`,
    payload
  );
  return data;
};

export const logout = (): AxiosPromise => api.post(`${URL}/logout`);
