import * as yup from 'yup';

export const initialValues = {
  name: '',
  logo: '',
  website: '',
  official_name: '',
  street_address: '',
  city: '',
  state: '',
  zip: '',
  mission_statement: '',
  ein: '',
  country: '',
  charity_type: '',
  contact_email: '',
};

export const urlRegEx =
  /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const validationSchema = yup.object().shape({
  name: yup.string().required('field is required!'),
  website: yup
    .string()
    .matches(urlRegEx, 'please enter a valid url!')
    .required('field is required!'),
  logo: yup.string().required('field is required!'),
  official_name: yup.string().required('field is required!'),
  city: yup.string().required('field is required!'),
  state: yup.string().required('field is required!'),
  street_address: yup.string().required('field is required!'),
  zip: yup.string().required('field is required!'),
  mission_statement: yup.string().required('field is required!'),
  ein: yup.string().required('field is required!'),
  country: yup.string().required('field is required!'),
  charity_type: yup.string().required('field is required!'),
  contact_email: yup.string().email('please enter a valid email'),
});
