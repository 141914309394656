import api from 'api/instance';
import { Gym, GymLocation } from 'types/gym';

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/gyms`;

export const fetchAllGyms = async (): Promise<Gym[]> => {
  const { data } = await api.get<Gym[]>(`${URL}/all`);
  return data;
};

export const createGym = async (details: GymLocation): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const updateGym = async (details: GymLocation): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, details);
  return data;
};

export const deleteGym = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/delete?location_id=${id}`);
  return data;
};
