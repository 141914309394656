import React from 'react';

import AuthGuard from 'components/core/AuthGuard';
import Navbar from 'components/core/Navbar';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import { Helmet } from 'react-helmet';
import { Switch, useLocation } from 'react-router-dom';
import Charities from 'routes/Charities';
import CharityCreate from 'routes/CharityCreate';
import CharityUpdate from 'routes/CharityUpdate';
import Dashboard from 'routes/Dashboard';
import Error from 'routes/Error';
import EventCreate from 'routes/EventCreate';
import EventEdit from 'routes/EventEdit';
import Events from 'routes/Events';
import Gym from 'routes/Gym';
import Login from 'routes/Login';
import Notifications from 'routes/Notifications';

// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

export const ROUTES = {
  LOGIN: {
    component: Login,
    path: '/login',
  },
  DASHBOARD: {
    component: Dashboard,
    path: '/dashboard',
  },
  ERROR: {
    component: Error,
    path: '/not-found',
  },
  EVENTS: {
    component: Events,
    path: '/events',
  },
  EVENT_CREATE: {
    component: EventCreate,
    path: '/event/create',
  },
  EVENT_EDIT: {
    component: EventEdit,
    path: '/event/:eventId',
  },
  GYM: {
    component: Gym,
    path: '/gym',
  },
  CHARITIES: {
    component: Charities,
    path: '/charities',
  },
  NOTIFICATIONS: {
    component: Notifications,
    path: '/notifications',
  },
  CHARITY_CREATE: {
    component: CharityCreate,
    path: '/charities/create',
  },
  CHARITY_UPDATE: {
    component: CharityUpdate,
    path: '/charities/update/:id',
  },
  // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
};

const Routes = (): JSX.Element => {
  const location = useLocation();
  const { pathname } = location;

  const auth = pathname === ROUTES.LOGIN.path;
  const showNavbar = pathname !== '/' && pathname !== ROUTES.LOGIN.path;

  const pathNotFound =
    Object.values(ROUTES)
      .map((route) => route.path)
      .filter((path) => pathname.includes(path)).length === 0;
  
  return (
    <>
      {auth && (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      
      {showNavbar && <Navbar />}
      <AuthGuard auth={auth} pathNotFound={pathNotFound}>
        <Switch>
          {Object.values(ROUTES).map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} exact />
          ))}
        </Switch>
      </AuthGuard>
    </>
  );
};

export default Routes;
