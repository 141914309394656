import api from "api/instance";
import { Notification } from "types/notification";

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/push_notifications`;

export const createNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/scheduled/create`, details);
  return data;
};

export const sendTestNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/test`, details);
  return data;
};

export const deleteNotification = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/scheduled/delete?id=${id}`);
  return data;
};

export const updateNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/scheduled/update`, details);
  return data;
};

export const getAllNotifications = async ({
  challenge_id,
}: {
  challenge_id?: number | null;
}): Promise<Notification[]> => {
  console.log("challenge_id in getAllNotis", challenge_id)
  const { data } = await api.get<Notification[]>(
    `${URL}/scheduled/list?challenge_id=${challenge_id}`
  );
  console.log("data in getAllNotis", data)
  return data;
};

export const getNotificationDetails = async (
  id: number
): Promise<Notification> => {
  const { data } = await api.get<Notification>(
    `${URL}/scheduled/read?id=${id}`
  );
  return data;
};
