import React from 'react';

import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import ConfirmationModalView from 'components/primitives/ConfirmationModal';
import { format } from 'date-fns';
import { Charity } from 'types/charities';

import { CharitiesGeneratedProps } from './Charities.props';

const CharitiesView = (props: CharitiesGeneratedProps): JSX.Element => (
  <Box display="flex" justifyContent="center">
    {props.isLoading ? (
      <LoadingIndicator />
    ) : props.isError ? (
      <Text color="Red" fontSize="40px" fontWeight="bold">
        ERROR
      </Text>
    ) : (
      <Box maxW="1300px" w="100%" mt={10} m={3}>
        <Text textAlign="center" fontSize="30px" fontFamily="Helvetica Neue">
          All Charities
        </Text>
        <Flex p={3} justifyContent="center" my={3}>
          <InputGroup
            w="100%"
            maxW="600px"
            boxShadow="0px 1px 7px 0px rgba(50, 50, 50, 0.26)"
          >
            <InputRightElement>
              <Icon name="search" color="gray.300" cursor="pointer" />
            </InputRightElement>
            <Input
              type="phone"
              placeholder="Search Charity by Name or ID"
              borderWidth="0px"
              value={props.searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.onSearchChange(e.target.value)
              }
            />
          </InputGroup>
        </Flex>
        <Flex flex={1} justifyContent="space-between">
          <Badge
            ml={4}
            variant="outline"
            variantColor="green"
            alignSelf="flex-end"
          >
            TOTAL CHARITIES - {props.charities.length}
          </Badge>
          <Button
            mr={4}
            fontSize="14px"
            fontWeight="bold"
            color="white"
            bg="#45C2CC"
            onClick={props.onNewClick}
          >
            CREATE NEW
          </Button>
        </Flex>
        <Stack overflowX="auto" px={4} mt={3}>
          <Flex
            flex={1}
            bg="#f1f1f1"
            p={2}
            minW="1000px"
            fontSize="12px"
            fontWeight="600"
            color="grey"
            borderRadius="5px"
          >
            <Text flex={0.5}>ID</Text>
            <Flex flex={1} mr={2} alignItems="center">
              <Text isTruncated>NAME</Text>
              <Icon
                name="arrow-up-down"
                size="8px"
                ml={1}
                cursor="pointer"
                onClick={() => props.sortBy('name')}
              />
            </Flex>
            <Text flex={1} mr={2} isTruncated>
              WEBSITE
            </Text>
            <Flex flex={0.5} mr={2} alignItems="center">
              <Text>DATE CREATED</Text>
              <Icon
                name="arrow-up-down"
                size="8px"
                ml={1}
                cursor="pointer"
                onClick={() => props.sortBy('metaCreated')}
              />
            </Flex>
            <Flex flex={0.12} />
          </Flex>
          <Stack bg="Yellow.200" mt={2} minW="1000px">
            {props.charities.map((charity: Charity) => {
              return (
                <Flex
                  key={charity.id}
                  flex={1}
                  mb={2}
                  p={1}
                  borderRadius="5px"
                  borderWidth="1px"
                  fontSize="14px"
                  alignItems="center"
                >
                  <Text flex={0.5}>{charity.id}</Text>
                  <Text flex={1} mr={2} isTruncated>
                    {charity.name}
                  </Text>
                  <Text flex={1} mr={2} isTruncated>
                    {charity.website}
                  </Text>
                  <Text flex={0.5}>
                    {format(new Date(charity.metaCreated), 'dd LLL R')}
                  </Text>
                  <Icon
                    name="edit"
                    size="16px"
                    color="grey"
                    onClick={() => props.onEditClick(charity.id)}
                    mt={1}
                    mr={4}
                  />
                  <ConfirmationModalView
                    message="Are you sure you want to permanently delete this charity?"
                    onConfirmClick={() => props.onDeleteClick(charity.id)}
                    isLoading={props.isDeleteLoading}
                  >
                    <Icon name="delete" size="16px" color="grey" />
                  </ConfirmationModalView>
                </Flex>
              );
            })}
          </Stack>
        </Stack>
      </Box>
    )}
  </Box>
);

export default CharitiesView;
