import api from 'api/instance';
import { Team } from 'types/team';

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/events/teams`;

export const updateTeamDetails = async (
  details: Partial<Team>
): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, details);
  return data;
};

export const createTeam = async (details: Omit<Team, 'id'>): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const deleteTeam = async (team_id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/delete?team_id=${team_id}`);
  return data;
};

export const uploadTeamImage = async (
  file: File,
  onProgress?: (a: ProgressEvent) => void
): Promise<string> => {
  const { data: preSignedUrl } = await api.get(
    `${URL}/image-upload-url/?filename=${file.name}`
  );
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.onload = () => {
      resolve(file.name);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`
          )
        );
      }
    };
    xhr.send(file);
  });
};
