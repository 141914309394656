import api from 'api/instance';
import { Event, EventDetails, EventItem } from 'types/events';

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/events`;

export const fetchAll = async (): Promise<EventItem[]> => {
  const { data } = await api.get<EventItem[]>(`${URL}/list`);
  return data;
};

export const fetchEventDetails = async (
  event_id: number
): Promise<EventDetails> => {
  const { data } = await api.get<any>(`${URL}/read?event_id=${event_id}`);
  return data;
};

export const updateEventDetails = async (
  details: Partial<Event>
): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, details);
  return data;
};

export const create = async (details: EventDetails): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const deleteEvent = async (eventId: number): Promise<any> => {
  const { data } = await api.get(`${URL}/delete?event_id=${eventId}`);
  return data;
};

type UploadImagePayload = {
  uri: string;
  name: string;
  type: string;
};

export const uploadEventImage = async (
  file: File,
  onProgress?: (a: ProgressEvent) => void
): Promise<string> => {
  const blob = file.slice(0, file.size, file.type);

  const newFile = new File([blob], file.name?.replace?.('&', ''), {
    type: file.type,
  });

  //@ts-ignore
  newFile.path = file?.path;

  const { data: preSignedUrl } = await api.get(
    `${URL}/image-upload-url/?filename=${newFile.name}`
  );

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.onload = () => {
      resolve(newFile.name);
    };

    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`
          )
        );
      }
    };
    xhr.send(newFile);
  });
};
