import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/core';
import { fetchEventDetails, updateEventDetails } from 'api/events';
import {
  createSponsor,
  deleteSponsor,
  updateSponsorDetails,
} from 'api/sponsors';
import { updateTeamDetails, createTeam, deleteTeam } from 'api/teams';
import { useMutation } from 'react-query';
import { EventDetails, Sponsor } from 'types/events';
import { Team } from 'types/team';

import { EventEditProps } from './EventEdit.props';
import EventEditView from './EventEdit.view';

const EventEditContainer = (props: EventEditProps): JSX.Element => {
  const [data, setData] = useState<EventDetails>();
  const [fetchEvent, { isLoading: isFetchingEvent }] =
    useMutation(fetchEventDetails);

  const id = parseInt(props.match.params.eventId);
  useEffect(() => {
    fetchEvent(id).then((values) => setData(values));
  }, [id, fetchEvent]);

  const [updateEventSubmit, { isLoading }] = useMutation(updateEventDetails, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Event updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      window.scroll({ top: 0, behavior: 'smooth' });
      fetchEvent(id).then((values) => setData(values));
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [updateTeamSubmit] = useMutation(updateTeamDetails, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Team updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [createTeamSubmit] = useMutation(createTeam, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Team created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [deleteTeamSubmit] = useMutation(deleteTeam, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Team deleted successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [deleteSponsorSubmit] = useMutation(deleteSponsor, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Sponsor deleted successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [updateSponsorSubmit] = useMutation(updateSponsorDetails, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Sponsor updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const [createSponsorSubmit] = useMutation(createSponsor, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Sponsor created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const toast = useToast();

  //SPONSOR FUNCTIONS
  const onSponsorSubmit = async (values: Partial<Sponsor>) => {
    if (values.sponsor_id) {
      await updateSponsorSubmit({
        challenge_id: values.challenge_id,
        sponsor_id: values.sponsor_id,
        top_banner: values.top_banner,
        banner_ad: values.banner_ad,
        banner_link: values.banner_link,
      } as Omit<Sponsor, 'logo' | 'name' | 'website'>);
    } else {
      await createSponsorSubmit(
        Object.assign(values as Sponsor, {
          challenge_id: parseInt(props.match.params.eventId, 10),
        })
      );
    }
    fetchEvent(id).then((values) => setData(values));
  };

  const onSponsorDelete = async ({
    challenge_id,
    sponsor_id,
  }: {
    challenge_id?: number;
    sponsor_id?: number;
  }) => {
    await deleteSponsorSubmit({ challenge_id, sponsor_id });
    fetchEvent(id).then((values) => setData(values));
  };

  //TEAM FUNCTIIONS
  const onTeamSubmit = async (values: Partial<Team>) => {
    if (values.id) {
      await updateTeamSubmit(values as Team);
    } else {
      await createTeamSubmit(
        Object.assign(values as Omit<Team, 'id'>, {
          challenge_id: parseInt(props.match.params.eventId, 10),
        })
      );
    }
    fetchEvent(id).then((values) => setData(values));
  };

  const onTeamDelete = async (team_id: number) => {
    await deleteTeamSubmit(team_id);
    fetchEvent(id).then((values) => setData(values));
  };

  return (
    <EventEditView
      data={data?.event}
      teams={data?.teams || []}
      sponsors={data?.sponsors || []}
      onSave={updateEventSubmit}
      onTeamSubmit={onTeamSubmit}
      onTeamDelete={onTeamDelete}
      isLoading={isLoading}
      isFetchingEvent={isFetchingEvent}
      onSponsorDelete={onSponsorDelete}
      onSponsorSubmit={onSponsorSubmit}
      challengeId={props.match.params.eventId}
      refetchEvent={() => fetchEvent(id).then((values) => setData(values))}
    />
  );
};

export default EventEditContainer;
