import api from 'api/instance';
import { Sponsor } from 'types/events';

const URL = `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/events/sponsors`;

export const updateSponsorDetails = async (
  details: Omit<Sponsor, 'name' | 'logo' | 'website'>
): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, details);
  return data;
};

export const createSponsor = async (
  details: Partial<Sponsor>
): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const deleteSponsor = async ({
  challenge_id,
  sponsor_id,
}: {
  challenge_id?: number;
  sponsor_id?: number;
}): Promise<any> => {
  const { data } = await api.get(
    `${URL}/delete?challenge_id=${challenge_id}&sponsor_id=${sponsor_id}`
  );
  return data;
};

export const uploadSponsorImage = async (
  file: File,
  onProgress?: (a: ProgressEvent) => void
): Promise<string> => {
  const { data: preSignedUrl } = await api.get(
    `${URL}/image-upload-url/?filename=${file.name}`
  );
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.onload = () => {
      resolve(file.name);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`
          )
        );
      }
    };
    xhr.send(file);
  });
};
