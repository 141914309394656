import React, { useEffect, useState } from 'react';

import { fetchAllCharities } from 'api/charities';
import { fetchAll } from 'api/events';
import { fetchAllGyms } from 'api/gym';
import { useQuery } from 'react-query';
import { EventItem } from 'types/events';

import { DashboardProps } from './Dashboard.props';
import DashboardView from './Dashboard.view';

const DashboardContainer = (props: DashboardProps): JSX.Element => {  
  const { data: events } = useQuery('events', fetchAll);
  const { data: charities } = useQuery('charities', fetchAllCharities);
  const { data: gyms } = useQuery('fetchGym', fetchAllGyms);
  const [eventStatus, setEventStatus] = useState({
    upcoming: 0,
    active: 0,
    expired: 0,
  });

  useEffect(() => {
    if (!!events) {
      const today = new Date();
      const upcoming: EventItem[] = [];
      const active: EventItem[] = [];
      const expired: EventItem[] = [];

      events.forEach((event: EventItem) => {
        if (new Date(event.start) < today && new Date(event.end) > today) {
          active.push(event);
        }
        if (new Date(event.start) > today) upcoming.push(event);
        if (new Date(event.end) < today) expired.push(event);
      });
      
      setEventStatus({
        upcoming: upcoming.length,
        active: active.length,
        expired: expired.length,
      });
    }
  }, [events]);

  return (
    <DashboardView
      eventStatus={eventStatus}
      charities={charities || []}
      gyms={gyms || []}
    />
  );
};

export default DashboardContainer;
