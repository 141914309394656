import axios from 'axios';
import { SessionStore } from '../effector/session/store';

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_GOOD_MOVE_BASE_DOMAIN}/v1/`,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const session = SessionStore.getState();

  if (session && session.key && request.headers) {
    request.headers.key = session.key;
  }

  return request;
});

instance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    const errObj = {
      error: true,
      Message: '',
      pending: false,
    };

    if (err.response && err.response.data) {
      errObj.Message = err.response.data;
    } else if (err.request) {
      errObj.Message = 'Problem connecting to server.';
    } else {
      errObj.Message = err.message;
    }

    return Promise.reject(errObj);
  }
);

export default instance;
