import React, { useEffect, useState } from "react";

import { Box, Spinner } from "@chakra-ui/core";
import { AuthGuardPublicProps } from "components/core/AuthGuard/AuthGuard.props";
import { useStore } from "effector-react";
import { SessionStore } from "../../../effector/session/store";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "routes";

// need to confirm where args are coming from
const AuthGuard = ({
  children,
  pathNotFound,
  auth,
}: AuthGuardPublicProps): JSX.Element => {
  const [redirect, setRedirecting] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();
  const session = useStore(SessionStore);
  const key = session?.key || "";

  useEffect(() => {
    const checkRedirect = (key: string) => {
      const sessionRedirectRoutes = ["/", ROUTES.LOGIN.path];

      // THIS IS ONLY FOR EVENTS -- THE REST OF THE ROUTES ARE FINE
      if (!key) return history.replace(ROUTES.LOGIN.path);
      if (sessionRedirectRoutes.includes(pathname)) history.replace(ROUTES.EVENTS.path);
    };

    checkRedirect(key);
    // eslint-disable-next-line
  }, [key, auth, pathNotFound, history]);

  return (
    <>
      {redirect ? (
        <Box
          display="flex"
          height="100vh"
          width="100%"
          alignItems="center"
          justifyContent="center"
          bg="purple.50"
        >
          <Spinner size="xl" />
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default React.memo(AuthGuard);
