import React, { useRef } from 'react';

import {
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';
import { logoutEvent } from '../../../effector/session/events';
import { MdMenu } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import logo from 'res/logo.png';
import { useResponsive } from 'utils/hooks';

import { NavbarProps } from './Navbar.props';

const NavbarView = (props: NavbarProps): JSX.Element => {
  const { pathname } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isMobile } = useResponsive();
  const btnRef = useRef(null);

  return (
    <Flex height="60px" shadow="md" bg="purple.800" justifyContent="center">
      <Flex
        px={2}
        flexDir="row"
        alignItems="center"
        width="100%"
        maxWidth="1300px"
        mx="auto"
      >
        {isMobile ? (
          <Flex w="100%" alignItems="center" justify="space-between">
            <Image size="50px" objectFit="contain" src={logo} />
            <MdMenu color="white" size={30} onClick={onOpen} />
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <Avatar ml={4} mt={4} size="lg" />
                <DrawerHeader>Welcome Back,</DrawerHeader>
                <DrawerBody display="flex" flexDirection="column">
                  <Link
                    bg={pathname === '/dashboard' ? '#4f4f4f' : 'white'}
                    p={4}
                    color={pathname === '/dashboard' ? 'white' : 'black'}
                    fontWeight="600"
                    href="/dashboard"
                  >
                    Dashboard
                  </Link>
                  <Link
                    bg={pathname === '/events' ? '#4f4f4f' : 'white'}
                    p={4}
                    color={pathname === '/events' ? 'white' : 'black'}
                    fontWeight="600"
                    href="/events"
                  >
                    Events
                  </Link>
                  <Link
                    bg={pathname === '/charities' ? '#4f4f4f' : 'white'}
                    p={4}
                    color={pathname === '/charities' ? 'white' : 'black'}
                    fontWeight="600"
                    href="/charities"
                  >
                    Charities
                  </Link>
                  <Link
                    bg={pathname === '/gym' ? '#4f4f4f' : 'white'}
                    p={4}
                    color={pathname === '/gym' ? 'white' : 'black'}
                    fontWeight="600"
                    href="/gym"
                  >
                    Gyms
                  </Link>
                  <Link
                    bg={pathname === '/notifications' ? '#4f4f4f' : 'white'}
                    p={4}
                    color={pathname === '/notifications' ? 'white' : 'black'}
                    fontWeight="600"
                    href="/notifications"
                  >
                    Notifications
                  </Link>
                </DrawerBody>
                <DrawerFooter>
                  <Button
                    variant="outline"
                    mr={3}
                    borderColor="#4f4f4f"
                    borderWidth="2px"
                    onClick={() => {
                      logoutEvent();
                      onClose();
                    }}
                  >
                    LOGOUT
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Flex>
        ) : (
          <>
            <Image size="50px" objectFit="contain" src={logo} />
            <Stack
              isInline
              flex="1"
              align="center"
              justifyContent="flex-end"
              flexDir="row"
              spacing={[5, 10]}
              fontSize={['xs', 'xs', 'md']}
            >
              <Link
                color={pathname === '/dashboard' ? 'teal.200' : 'white'}
                href="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                color={pathname === '/events' ? 'teal.200' : 'white'}
                href="/events"
              >
                Events
              </Link>
              <Link
                color={pathname === '/charities' ? 'teal.200' : 'white'}
                href="/charities"
              >
                Charities
              </Link>
              <Link
                color={pathname === '/gym' ? 'teal.200' : 'white'}
                href="/gym"
              >
                Gyms
              </Link>
              <Link
                color={pathname === '/notifications' ? 'teal.200' : 'white'}
                href="/notifications"
              >
                Notifications
              </Link>
              <Menu>
                <MenuButton>
                  <Avatar />
                </MenuButton>
                <MenuList>
                  <MenuGroup>
                    <MenuItem onClick={() => logoutEvent()}>Logout</MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Stack>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default NavbarView;
